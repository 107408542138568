<template>
  <div>
    <b-row>
      <b-col
        md="2"
      >
        <b-form-group
          label="CLIENTE ID"
          label-for="op.cliente_id"
        >
          <b-form-input
            id="op.cliente_id"
            :value="op.cliente_id"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="10"
      >
        <b-form-group
          label="Nome"
          label-for="op.cliente_nome"
        >
          <b-form-input
            id="op.cliente_nome"
            :value="op.cliente"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="10"
      >
        <b-form-group
          label="Aluno"
          label-for="aluno"
        >
          <b-form-input
            id="op.aluno"
            :value="op.aluno"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        md="2"
      >
        <b-form-group
          label="OPERACAO ID"
          label-for="op_id"
        >
          <b-form-input
            id="op_id"
            :value="op.id"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="10"
      >
        <b-form-group
          label="DESCRIÇÃO"
          label-for="op_descricao"
        >
          <b-form-input
            id="op_descricao"
            :value="op.descricao"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="3"
      >
        <b-form-group
          label="NUMERO OPERAÇÃO"
          label-for="op_num"
        >
          <b-form-input
            id="op_num"
            :value="op.numero_operacao"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="3"
      >
        <b-form-group
          label="STATUS OPERAÇÃO"
          label-for="op_status"
        >
          <b-form-input
            id="op_status"
            :value="op.status"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="3"
      >
        <b-form-group
          label="DATA VENCIMENTO"
          label-for="op_date_vencimento"
        >
          <b-form-input
            id="op_date_vencimento"
            :value="op.data_vencimento"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        md="3"
      >
        <b-form-group
          label="DATA PROCESSAMENTO"
          label-for="op_data_processamento"
        >
          <b-form-input
            id="op_data_processamento"
            :value="op.data_processamento"
            readonly
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="6"
        md="4"
      >
        <b-form-group
          label="VALOR NOMINAL"
          label-for="op_valor_nominal"
        >
          <b-form-input
            id="op_valor_nominal"
            :value="op.valor_nominal"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="4"
      >
        <b-form-group
          label="VALOR ATUALIZADO"
          label-for="op_valor_atualizado"
        >
          <b-form-input
            id="op_valor_atualizado"
            :value="op.valor_atualizado"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="4"
      >
        <b-form-group
          label="DESCONTO"
          label-for="op_valor_desconto"
        >
          <b-form-input
            id="op_valor_desconto"
            :value="op.desconto"
            readonly
          />
        </b-form-group>
      </b-col>
      <b-card title="Adicionais:">
        <div
          v-if="op.adicionais"
        >
          <label class="ml-1">ADICIONAIS</label>
          <b-row
            v-for="item in Object.keys(op.adicionais)"
            :key="item"
          >
            <b-col
              cols="12"
              class="mb-1 ml-1"
            >
              {{ item.replace('_', ' ') }}:
              <b-form-input
                id="op.adicionais"
                :value="op.adicionais[item]"
                readonly
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-row>
  </div>
</template>
<script>

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    idOp: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      op: {
        id: '',
        aluno: '',
        numero_operacao: '',
        data_vencimento: '',
        data_processamento: '',
        valor_nominal: '',
        valor_atualizado: '',
        descricao: '',
        desconto: '',
        adicionais: '',
        cliente_id: '',
        cliente: '',
        cliente_endereco: '',
        cliente_bairro: '',
        status: '',
      },
    }
  },
  async mounted() {
    await axios.get(`api/v1/operacoes/edit/${this.idOp}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    }).then(async res => {
      const dt = res.data.dados.operacao

      // DADOS OPEACAO
      this.op.id = dt.id
      this.op.numero_operacao = dt.numero_operacao
      // this.op.cliente = dt.nome_cliente
      this.op.data_vencimento = this.dataHora(dt.data_vencimento, true)
      this.op.data_processamento = this.dataHora(dt.created_at, true)
      this.op.valor_nominal = this.valorBr(dt.valor_nominal, true)
      this.op.valor_atualizado = this.valorBr(dt.valor_atualizado, true)
      this.op.descricao = dt.descricao
      this.op.desconto = dt.desconto
      // this.op.pendencia = dt.nome_pendencia
      this.op.status = dt.status_operacao.nome

      // INFO CLIENTE
      this.op.cliente_id = dt.cliente.id
      this.op.cliente = dt.cliente.nome
      this.op.aluno = dt.aluno
      this.op.adicionais = JSON.parse(dt.adicionais)
      // this.op.cliente_cpf_cnpj = dt.cliente.cpf_cnpj
      this.op.cliente_endereco = dt.cliente.endereco
      this.op.cliente_bairro = dt.cliente.bairro
      // this.op.cliente_cep = dt.cliente.cep
    })
  },
}
</script>
